import { Component } from "react";
import { isConnected, connect } from '../assets/scripts/wallet.js';
import * as contract from '../assets/scripts/contract.js';
import { log } from "../assets/scripts/logger.js";

export default class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isWalletConnected: false
    }
  }

  connect = (e) => {
    e.preventDefault();
    if(!isConnected()) {
      connect();
    }
  };

  componentDidMount() {

    this.setState({isWalletConnected: isConnected()});

    document.addEventListener('onAccountUpdate', () => {

      const connected = isConnected();

      this.setState({isWalletConnected: connected});

      if(connected) {
        this.queryGhosts();
      }

    });

  }

  queryGhosts = async () => {

    if(!isConnected()) {
      return;
    }

    const ghosts = await contract.queryGhosts();

    if(ghosts) {
      log("[PROFILE]: ghosts " + JSON.stringify(ghosts));
    }
    
    this.setState({ghosts: ghosts});

  };

  render() {
    var rows = [];
    if(this.state.ghosts && this.state.ghosts.length > 0) {
      for (var i = 0; i < this.state.ghosts.length; i++) {
        rows.push(
          <tr className={"profile-list-item"} key={i + 1}>
            <td>
              {/* <span>{this.state.ghosts[i].edition}</span> */}
              <span>{this.state.ghosts[i].name}</span>
              <img alt="AVAXGHOST NFT" src={this.state.ghosts[i].image.replace("ipfs://", "https://avaxghost.mypinata.cloud/ipfs/")}></img>
            </td>
            <td>
              <span>{this.state.ghosts[i].description}</span>
              {this.state.ghosts[i].attributes.map(element => 
                <div className="profile-list-item-attribute">
                  <span>{element.trait_type}</span>
                  <span>{element.value}</span>
                </div>
              )}
            </td>
          </tr>
        );
      }
    } else {
      rows.push(
        <div>
          <span>You Need to Mint AVAXGHOST First!</span>
          <br />
          <a href="/" className="banner-one__btn thm-btn mint-btn">
            <span>MINT</span>
          </a>
        </div>
      );
    }
    return (
      <section className="profile-banner" id="banner">
        <div className="profile-overlay"></div>
        <div className="profile-container">
          <div className="row">
            <div className="col-xl-12">
              <div className="col-xl-3 col-lg-6"></div>
              <div className="banner-one__content">
                <h1 className="banner-one__title">
                  MY GHOSTS
                </h1>
                {this.state.isWalletConnected
                  ? 
                  <div className="profile-list-container">
                    <table className="profile-list">
                      <tbody>
                        {rows}
                      </tbody>
                    </table>
                  </div>
                  :
                  <a href="#" className="banner-one__btn thm-btn mint-btn" onClick={this.connect}>
                    <span>CONNECT</span>
                  </a>
                }
              </div>
              <div className="col-xl-3 col-lg-6"></div>
            </div>
          </div>
        </div>
      </section>
    )
  }

}