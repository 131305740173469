import React from "react";
import Layout from "../components/Layout";
import Nav from "../components/Nav";
import Profile from "../components/Profile";

const MyProfile = () => (
  <Layout pageTitle="AVAXGHOST NFT Game">
    <Nav />
    <Profile />
  </Layout>
);

export default MyProfile;